import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, IconButton, Stack, Grid, Radio, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CustomButton from '../../../components/common/CustomButton';
import { horecaApi } from '../../../redux/api/api';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PriceDetailsComp from '../../../components/common/PriceDetailsComp';
import { getOrgType } from '../../../utils/org';
import useResponsiveStyles from '../../../hooks/useResponsiveStyles';

const PlaceOrderDialogue = ({ content, open, onClose, handelCreateOrder, priceDetails, title = "Confirm and Pay", itemLength, heading = 'Review', addressId, setAddressId }) => {

  const { orgId } = useParams();
  const { isMobile } = useResponsiveStyles();
  const [add, setAdd] = useState([]);
  const [getAllPinCodes] = horecaApi.endpoints.getAllPinCodes.useLazyQuery();

  const user = useSelector((state) => state.auth.user)
  const selectedPincode=useSelector((state)=>state.app.preference.selectedPinCode)
  const orgType = getOrgType(user, orgId);
  
  useEffect(() => {
    (
      async () => {
        if (orgType === "customer") {
          const result = await getAllPinCodes(orgId);
          if (result?.data) {
            setAdd(result?.data?.addresses);
            if (!addressId) {

            }
          }
        }
      }
    )()
  }, [])
 
  const handleChange = (add) => {
    setAddressId(add?.id)
  };

  const controlProps = (item) => ({
    checked: (addressId === item.id || selectedPincode.pincode === item.pincode),
    onChange: (e) => handleChange(item),
    value: item,
    name: 'color-radio-button-demo',
    inputProps: { 'aria-label': item.id },
  });
  
  const deliveryPincodes = priceDetails?.deliversAt?.flatMap(vendor => vendor.deliversAt);

  const filteredAddresses = add?.filter((address) =>
    selectedPincode?.pincode?.includes(address.pincode)
  );


  return (
    <Dialog
      maxWidth={"lg"}
      scroll={"paper"}
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            padding: '1rem'
          },
        },
      }}
    >
      <DialogTitle>
        <Typography variant='h5' sx={{ textAlign: 'center' }}>{heading}</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose} // Call onClose function when close icon is clicked
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            // color: 'inherit',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ mt: '2rem', p : isMobile ? 0 : 2 }}>
        {content}
        <Stack direction={'row'} justifyContent={'flex-end'} mt={'1rem'}>
          {/* <Grid container spacing={2} justifyContent={'flex-end'} xs={3}>
            {priceDetails &&
              [
                { label: "Subtotal", value: priceDetails?.subtotal ? priceDetails?.subtotal : '--' },
                { label: "Shipping", value: priceDetails?.shipping ? priceDetails?.shipping : '--' },
                { label: "Discount", value: priceDetails?.discount ? priceDetails?.discount : '--' },
                { label: "Taxes", value: priceDetails?.taxes ? priceDetails?.taxes : '--' },
                { label: "Total", value: priceDetails?.total ? priceDetails?.total : '--' },
              ].map((vendorDet, index) => (
                <>
                  <Grid item xs={6} container >
                    <Typography sx={{ color: "grey" }} variant="body2">
                      {vendorDet.label}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} padding={'auto'}>
                    <Typography variant="body2">₹{vendorDet.value}</Typography>
                  </Grid>
                </>
              ))
            }
          </Grid> */}
          <PriceDetailsComp priceDetails={priceDetails} style={{ justifyConten: 'flex-end', width:'100%' }} xs={3} />
        </Stack>
        <Stack width={'100%'} direction={'row'} justifyContent={'space-between'} spacing={3}>
        {!isMobile && <Stack width={'80%'}>
            <Typography variant='h5'>Addresses</Typography>
            {
              filteredAddresses?.map((data, index) => (
                <Stack direction={'row'} gap={'1rem'} alignItems={'center'} key={index}>
                  <Radio {...controlProps(data)} />
                  <Typography variant='body1'>
                    <span>{data?.pincode ? `${data?.landmark ? data?.landmark + ", " : ""}${data?.line1 ? data?.line1 + ", " : ""}${data?.city ? data?.city + ", " : ""}${data?.state ? data?.state + ", " : ""}${data?.pincode ?? ""}` : ""}
                    </span>
                  </Typography>
                </Stack>
              ))
            }
          </Stack>}
          <Stack mt={'1rem'} direction={'row'} justifyContent={'center'} width={isMobile ? '100%' : '20%'} sx={{ py: 2 }}>
            <CustomButton
              onClick={() => handelCreateOrder()}
              variant={"contained"}
              label={title}
              disabled={itemLength ? itemLength?.length > 0 ? false : true : false}
            />
          </Stack>
        </Stack>
        {
          isMobile &&
          <Box>
            <Typography variant='h5'>Addresses</Typography>
            {
              filteredAddresses?.map((data, index) => (
                <Stack direction={'row'} gap={'1rem'} alignItems={'center'} key={index}>
                  <Radio {...controlProps(data)} />
                  <Typography variant='body1'>
                    <span>{data?.pincode ? `${data?.landmark ? data?.landmark + ", " : ""}${data?.line1 ? data?.line1 + ", " : ""}${data?.city ? data?.city + ", " : ""}${data?.state ? data?.state + ", " : ""}${data?.pincode ?? ""}` : ""}
                    </span>
                  </Typography>
                </Stack>
              ))
            }
          </Box>
        }
      </DialogContent>
    </Dialog>
  )
}

export default PlaceOrderDialogue;
